import axios from "axios";
import { hideLoading, showLoading, showModel, showToast } from "@/utils/tools";
import * as storage from "./storage";
import crypto from "./crypto";
import Qs from "qs";
import store from "@/store";

const service = axios.create({
  baseURL: process.env.VUE_APP_HOST + process.env.VUE_APP_API,
  timeout: 120000,
});
function trimNullFiled(obj) {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i] && obj[i] == null) {
        obj.splice(i, 1);
      } else {
        trimNullFiled(obj[i]);
      }
    }
  } else {
    if (obj == null) {
      return;
    }
    Object.keys(obj).forEach(function (key) {
      if (obj[key] && obj[key] == null) {
        delete obj[key];
      }
      if (typeof obj[key] === "object") {
        trimNullFiled(obj[key]);
      }
    });
  }
}

service.interceptors.request.use(
  async (config) => {
    if (
      process.env.NODE_ENV === "development" &&
      (config.params || config.data)
    ) {
      console.group(`${config.url}请求数据`);
      if (config.params || config.data) {
        console.table(config.params || config.data);
      }
      console.groupEnd();
    }
    if (!config.noLoading) {
      showLoading("请求中，请稍后");
    }
    if (!config.notEncrypt) {
      // 需要加密
      const currentTime = new Date().getTime();
      const publicKeyExpireTime = storage.getStore("webPublicKeyExpireTime") || 0;
      if (parseInt(publicKeyExpireTime) < currentTime) {
        // 公钥过期
        const publicKeyDate = await crypto.getPublic(); // 获取公钥
        config.RSAEncryptKey = publicKeyDate.publicKey;
        storage.setStore("webPublicKey", config.RSAEncryptKey);
        storage.setStore("webPublicKeyExpireTime", publicKeyDate.expireTime);
      }
      if (!config.RSAEncryptKey) {
        config.RSAEncryptKey = storage.getStore("webPublicKey") || "";
      }
      config.AESEncryptKey = crypto.generateRandomCode();
      config.headers["Content-Type"] = "application/json";
      if (config.method.toLocaleLowerCase() === "get") {
        const parseData = Qs.stringify(config.data);
        if (parseData) {
          trimNullFiled(parseData);
        }
        console.log(parseData);
        config.params = {
          x_enc: crypto.encryptByAES(parseData, config.AESEncryptKey),
        };
      } else {
        config.data = crypto.encryptByAES(
          JSON.stringify(config.data),
          config.AESEncryptKey
        );
      }
      config.headers["x-key"] = crypto.encryptByRSA(
        config.AESEncryptKey,
        config.RSAEncryptKey
      );
    } else if (config.method.toLocaleLowerCase() === "get") {
      config.params = config.data;
    }
    if (storage.getStore("webToken")) {
      config.headers["Authorization"] = `Bearer ${storage.getStore(
        "webToken"
      )}`;
    }
    if (storage.getStore("webCurrentRole")) {
      config.headers["Role"] = storage.getStore("webCurrentRole");
    }
    return config;
  },
  (error) => {
    showToast({ type: error, message: error.message });
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    hideLoading();
    if (!res.config.notEncrypt && res.data.data && res.config.AESEncryptKey) {
      res.data.data = JSON.parse(
        crypto.decryptByAES(res.data.data, res.config.AESEncryptKey)
      );
    }
    if (process.env.NODE_ENV === "development") {
      console.group(`${res.config.url}返回数据`);
      console.dir(res.data);
      console.groupEnd();
    }
    if (res.data.url) {
      window.location = res.data.data.url;
    }

    if (res.data && res.data.code < 1) {
      showToast({ message: res.data.msg, type: "error" });
      return Promise.reject(res.data);
    } else {
      return res.data;
    }
  },
  (error) => {
    hideLoading();
    if (error && error.message === "Network Error") {
      showModel("网络异常，请检查网络是否连接正常！", "温馨提示", {
        confirmButtonText: "确定",
      });
    } else if (error && error.message === "ECONNABORTED") {
      showToast({
        message: "连接超时，请稍后再试",
        duration: 0,
        type: "error",
      });
    } else if (error.response) {
      switch (error.response.status) {
        case 403:
          break;
        case 404:
          showToast({
            message: `网络请求不存在，请检查地址：${error.config.url}`,
            duration: 0,
            type: "error",
          });
          break;
        case 401:
          showModel({
            content: "登录失效！请重新登录",
            title: "温馨提示",
            showClose: false,
            confirm: () => {
              // 返回登录页面
              storage.clearStore("webToken");
              storage.clearStore("userToken");
              storage.clearStore("webExpire");
              storage.clearStore("webUserInfo");
              store.commit("SET_AUTH_INFO", {});
              store.commit("SET_TOKEN", "");
              window.location.replace("https://www.sclpa.cn/Default.aspx#");
            },
          });
          break;
        case 500:
          showToast({ message: "服务器内部错误", type: "warning" });
          break;
        case 503:
          showToast({ message: "无法请求服务器", type: "warning" });
          break;
        default:
          showToast({ message: "未知异常", type: "warning" });
          break;
      }
    }
    return Promise.reject(error);
  }
);
export default service;
